import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, unref as _unref, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "my-4 px-10 py-1"
}
const _hoisted_2 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_3 = {
  key: 1,
  class: "flex items-center"
}
const _hoisted_4 = {
  key: 2,
  class: "flex items-center"
}
const _hoisted_5 = { class: "flex space-x-4 mb-3" }
const _hoisted_6 = { class: "font-bold" }
const _hoisted_7 = { className: "grid grid-cols-1 2xl:gap-4 gap-2 lg:grid-cols-2 xl:grid-cols-3" }
const _hoisted_8 = {
  class: "box 2xl:h-90 h-full w-full relative overflow-hidden",
  "data-aos": "zoom-out-up",
  "data-aos-duration": "900"
}
const _hoisted_9 = {
  key: 0,
  class: "mt-5"
}
const _hoisted_10 = {
  class: "box 2xl:h-90 h-full w-full relative overflow-hidden",
  "data-aos": "zoom-out-up",
  "data-aos-duration": "900"
}
const _hoisted_11 = { class: "w-36" }
const _hoisted_12 = { class: "cursor-pointer group hover:text-blue-500 text-2xl ml-1" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { class: "mt-2 flex" }
const _hoisted_15 = { class: "font-semibold w-5/12 truncate" }
const _hoisted_16 = {
  key: 1,
  class: "h-full flex justify-center items-center font-bold text-2xl"
}
const _hoisted_17 = {
  class: "box 2xl:h-90 h-full w-full relative overflow-hidden",
  "data-aos": "zoom-out-up",
  "data-aos-duration": "900"
}
const _hoisted_18 = { class: "w-36" }
const _hoisted_19 = { class: "cursor-pointer group hover:text-blue-500 text-2xl ml-1" }
const _hoisted_20 = { class: "float-right my-3" }
const _hoisted_21 = {
  key: 0,
  class: "mt-5"
}
const _hoisted_22 = { class: "mt-5" }
const _hoisted_23 = { class: "box py-5" }
const _hoisted_24 = { class: "flex justify-between mb-6" }
const _hoisted_25 = { class: "flex justify-end items-center space-x-3" }
const _hoisted_26 = {
  key: 0,
  class: "flex items-center space-x-2"
}
const _hoisted_27 = ["onClick"]
const _hoisted_28 = { class: "mt-2" }

import { computed, watch, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { SUPPLY_CHAIN_MANAGEMENT } from "@ems/constants";
import SupplyChainManamentModule from "@ems/containers/SupplyChainManagement/SupplyChainManament.module";
import MetaSupplyChainManamentModule from "@/store/modules/MetaSupplyChainManament";
import { formatLocalDateTime, getYear } from "@/utils/helpers/convertDateTime";
import dayjs from "dayjs";
import MissPermission from "@ems/containers/SupplyChainManagement/MissPermission.vue";
import BarChart from "@/components/AnyChart/BarChart.vue";
import Process from "../../../../../shared/components/Process.vue";
import LineChart from "@/components/AnyChart/LineChart.vue";
import BaseSvgIcon from "@/components/BaseSvgIcon.vue";
import BaseSpinner from "@/components/BaseSpinner.vue";
import { convertRangeMonthTilNow } from "@/utils/helpers/convertDataChart";
import { IReInvitationDTO } from "@/models/SupplyChainManagement";
import TenantUserModule from "@ems/containers/Tenant/TenantUser.module";

const minYear = 2021;


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const TOOLTIP_DATA = {
  PROCESS_CHART: `Each bar is calculated by taking the sum of supply chain's renewable energy divided by supply chain's project annual electricity consumption * 100%`,
  LINE_CHART: `Each point is calculated by taking the sum of supply chain's renewable energy divided by supply chain's project annual electricity consumption * 100%`,
};

const STATUS_RENEWABLE = {
  ACTIVE: "Activated",
};

const route = useRoute();
const router = useRouter();

const pagination = {
  CurrentPage: 1,
  Limit: 10,
  //TotalRow: 20,
};
const supplyChainData = computed(
  () => SupplyChainManamentModule.listDataGetSupplyChain
);
const paginationSupplyChain = computed(
  () => SupplyChainManamentModule.paginationGetter
);
const isLoadingSupplyChainsData = computed(
  () => SupplyChainManamentModule.isLoadingSupplyChainManagementGetter
);

const performanceChartData = computed(
  () => SupplyChainManamentModule.listDataGetProgressChartGetter
);

const isLoadingGetProgressChart = computed(
  () => SupplyChainManamentModule.isLoadingGetProgressChartGetter
);

const isLoadingGetLineChart = computed(
  () => SupplyChainManamentModule.isLoadingGetLineChartGetter
);

const lineChartData = computed(
  () => SupplyChainManamentModule.listDataGetLineChartGetter
);

const isLoadingGetBarCharts = computed(
  () => SupplyChainManamentModule.isLoadingGetBarChartGetter
);

const barChartData = computed(
  () => SupplyChainManamentModule.listDataGetBarChartGetter
);
const isLoadingSendInvitation = computed(
  () => SupplyChainManamentModule.isLoadingSendInvitationGetter
);
const maxNumPercent = computed(() => {
  return Math.max.apply(
    null,
    SupplyChainManamentModule.listDataGetProgressChartGetter.map(
      (item) => item.RenewablePerformance
    )
  );
});

const totalActive = computed(
  () => SupplyChainManamentModule.getTotalInvitationGetter
);

const isLoadingTotalActive = computed(
  () => SupplyChainManamentModule.isLoadingTotalInvitationGetter
);

const checkRole = computed(
  () => MetaSupplyChainManamentModule.getCheckRoleGetter
);

const isLoadingCheckRole = computed(
  () => MetaSupplyChainManamentModule.isLoadingCheckRoleGetter
);

const handleSizeChange = () => {
  //Todo implement feature
};
const year = dayjs().year();

const handleCurrentChange = (value: number) => {
  console.log(value);
  pagination.CurrentPage = value;
  //Todo implement feature
  SupplyChainManamentModule.getSupplyChainData({ year, pagination });
};

watch(checkRole, () => {
  callAPI();
});
onMounted(() => {
  callAPI();
});
const tenantProfile = computed(() => TenantUserModule.dataTenantProfileGetter);
const handleSendInvitation = (index: any, row: any) => {
  const data = {
    TenantSupplyChainId: row.TenantSupplyChainId,
    ContactName: row.ContactName,
    TenantName: tenantProfile.value.Name,
  } as IReInvitationDTO;
  SupplyChainManamentModule.resendInvitations(data);
};
const callAPI = () => {
  if (checkRole.value) {
    SupplyChainManamentModule.getSupplyChainData({ year, pagination });
    TenantUserModule.getTenantProfile();
    SupplyChainManamentModule.getProgressChart({
      year,
      MonthType: dayjs().month() + 1,
    });

    SupplyChainManamentModule.getLineChart();
    SupplyChainManamentModule.getBarChart({ year });
    SupplyChainManamentModule.getTotalInvitation();
  }
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _directive_loading = _resolveDirective("loading")!

  return (isLoadingCheckRole.value)
    ? (_openBlock(), _createBlock(BaseSpinner, {
        key: 0,
        fullScreen: ""
      }))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass([
      '2xl:space-y-6 my-3',
      isLoadingSendInvitation.value && 'isSubmitting-rounded',
    ])
      }, [
        (checkRole.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass([
          'flex w-1/2 justify-evenly absolute left-72',
          [_ctx.styles['top-minus-6']],
        ])
              }, [
                (!isLoadingTotalActive.value)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                      _cache[1] || (_cache[1] = _createElementVNode("p", { class: "mr-3" }, "Total Invited:", -1)),
                      _createElementVNode("p", {
                        class: _normalizeClass(['font-bold', _ctx.styles['text-30']])
                      }, _toDisplayString(totalActive.value.TotalInvited || 0), 3)
                    ]))
                  : _createCommentVNode("", true),
                (!isLoadingTotalActive.value)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                      _cache[2] || (_cache[2] = _createElementVNode("p", { class: "mr-3" }, "Total Active:", -1)),
                      _createElementVNode("p", {
                        class: _normalizeClass(['font-bold', _ctx.styles['text-30']])
                      }, _toDisplayString(totalActive.value.TotalActive || 0), 3)
                    ]))
                  : _createCommentVNode("", true),
                (!isLoadingTotalActive.value)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "mr-3" }, [
                        _createElementVNode("p", null, "Monthly Active"),
                        _createElementVNode("p", { class: "text-xs" }, "of total invited")
                      ], -1)),
                      _createElementVNode("p", {
                        class: _normalizeClass(['font-bold', _ctx.styles['text-30']])
                      }, _toDisplayString(`${
                totalActive.value.TotalMonthlyActive
                  ? totalActive.value.TotalMonthlyActive.toFixed(1)
                  : 0
              } %`), 3)
                    ]))
                  : _createCommentVNode("", true)
              ], 2)), [
                [_directive_loading, isLoadingTotalActive.value]
              ]),
              _createElementVNode("ol", _hoisted_5, [
                _createElementVNode("li", null, [
                  _createVNode(_component_router_link, { to: "/" }, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createTextVNode("Home")
                    ])),
                    _: 1
                  })
                ]),
                _cache[6] || (_cache[6] = _createElementVNode("li", null, ">", -1)),
                _createElementVNode("li", _hoisted_6, [
                  _createVNode(_component_router_link, { to: "" }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode(" Supply Chain Management")
                    ])),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createElementVNode("h1", {
                    class: _normalizeClass(['mt-2 text-left font-bold text-sm', _ctx.styles['family-none']])
                  }, " Supply Chain's Renewable Performance Distribution ", 2),
                  (!isLoadingGetBarCharts.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createVNode(BarChart, {
                          class: _normalizeClass(_ctx.styles['column-size']),
                          data: barChartData.value,
                          colors: ['#176604', '#176604'],
                          xTitle: "Renewable performance",
                          yTitle: "No. of companies",
                          barsPadding: 0.2,
                          seriesName: "Companies"
                        }, null, 8, ["class", "data"])
                      ]))
                    : _createCommentVNode("", true)
                ])), [
                  [_directive_loading, isLoadingGetBarCharts.value]
                ]),
                _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("h1", {
                    class: _normalizeClass(['mt-2 text-left font-bold text-sm', _ctx.styles['family-none']])
                  }, [
                    _cache[7] || (_cache[7] = _createTextVNode(" TOP RENEWABLE PERFORMANCE COMPANIES (ANNUAL EST.) ")),
                    _createVNode(_component_el_tooltip, {
                      effect: "light",
                      placement: "bottom"
                    }, {
                      content: _withCtx(() => [
                        _createElementVNode("p", _hoisted_11, _toDisplayString(TOOLTIP_DATA.PROCESS_CHART), 1)
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("i", _hoisted_12, [
                          _createVNode(BaseSvgIcon, {
                            class: "inline-block w-6 h-6",
                            name: "infoIcon"
                          })
                        ])
                      ]),
                      _: 1
                    })
                  ], 2),
                  (!isLoadingGetProgressChart.value && performanceChartData.value.length > 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(performanceChartData.value, (item, index) => {
                          return (_openBlock(), _createElementBlock("div", { key: index }, [
                            _createElementVNode("div", _hoisted_14, [
                              _createElementVNode("p", _hoisted_15, _toDisplayString(item.CompanyName), 1),
                              _createVNode(Process, {
                                numPercent: item.RenewablePerformance,
                                maxNumPercent: maxNumPercent.value
                              }, null, 8, ["numPercent", "maxNumPercent"])
                            ])
                          ]))
                        }), 128))
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_16, " NO DATA "))
                ])), [
                  [_directive_loading, isLoadingGetProgressChart.value]
                ]),
                _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_17, [
                  _createElementVNode("h1", {
                    class: _normalizeClass(['mt-2 text-left font-bold text-sm', _ctx.styles['family-none']])
                  }, [
                    _cache[8] || (_cache[8] = _createTextVNode(" Supply Chain's Performance Trend Overview ")),
                    _createVNode(_component_el_tooltip, {
                      effect: "light",
                      placement: "bottom"
                    }, {
                      content: _withCtx(() => [
                        _createElementVNode("p", _hoisted_18, _toDisplayString(TOOLTIP_DATA.LINE_CHART), 1)
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("i", _hoisted_19, [
                          _createVNode(BaseSvgIcon, {
                            class: "inline-block w-6 h-6",
                            name: "infoIcon"
                          })
                        ])
                      ]),
                      _: 1
                    })
                  ], 2),
                  _createElementVNode("p", _hoisted_20, _toDisplayString(_unref(convertRangeMonthTilNow)(5)), 1),
                  (!isLoadingGetLineChart.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                        _createVNode(LineChart, {
                          class: _normalizeClass(_ctx.styles['column-size']),
                          data: lineChartData.value,
                          legendStyle: {
                position: 'bottom',
                fontSize: 12,
                padding: [15, 0, 0, 0],
              },
                          lineMarker: "circle",
                          colors: ['#17A46D', '#17A46D'],
                          seriesName: "Suppliers performance"
                        }, null, 8, ["class", "data"])
                      ]))
                    : _createCommentVNode("", true)
                ])), [
                  [_directive_loading, isLoadingGetLineChart.value]
                ])
              ]),
              _createElementVNode("div", _hoisted_22, [
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("div", _hoisted_24, [
                    _cache[9] || (_cache[9] = _createElementVNode("p", { class: "text-xl 2xl:text-2xl" }, "Supply Chain Partner List", -1)),
                    _createElementVNode("div", _hoisted_25, [
                      _createElementVNode("button", {
                        type: "button",
                        class: "btn btn--green-primary",
                        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (
                  _unref(router).push(
                    `${_unref(route).path}/${_unref(SUPPLY_CHAIN_MANAGEMENT).INVITE_PARTNER.path}`
                  )
                ), ["prevent"]))
                      }, " Invite Partner ")
                    ])
                  ]),
                  _withDirectives((_openBlock(), _createBlock(_component_el_table, {
                    class: "w-full table-custom xl:mb-1",
                    data: supplyChainData.value,
                    stripe: "",
                    height: "66vh",
                    "cell-class-name": "text-cafe-dark",
                    "header-row-class-name": "tableHeaderRow",
                    "row-class-name": "tableRow"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_table_column, {
                        prop: "CompanyName",
                        label: "Company Name"
                      }),
                      _createVNode(_component_el_table_column, {
                        prop: "CountryName",
                        label: "Country"
                      }),
                      _createVNode(_component_el_table_column, {
                        prop: "Status",
                        label: "Status"
                      }),
                      _createVNode(_component_el_table_column, {
                        prop: "RenewableGoalRatio",
                        label: "Renewable Goal"
                      }, {
                        default: _withCtx((scope) => [
                          _createElementVNode("p", null, _toDisplayString(`${
                      scope.row.RenewableGoalRatio
                    }% / ${new Date().getFullYear()}`), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_table_column, { label: "Renewable Performance" }, {
                        default: _withCtx((scope) => [
                          _createTextVNode(_toDisplayString(scope.row.RenewablePerformance?.toFixed(0) + "%"), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_table_column, {
                        prop: "Email",
                        label: "POC Email"
                      }),
                      _createVNode(_component_el_table_column, { label: "Last login" }, {
                        default: _withCtx((scope) => [
                          _createTextVNode(_toDisplayString(minYear > _unref(getYear)(scope.row.LastTimeLogin) ||
                scope.row.Status !== STATUS_RENEWABLE.ACTIVE
                  ? "NA"
                  : _unref(formatLocalDateTime)(scope.row.LastTimeLogin)), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_table_column, {
                        label: "Action",
                        width: "80"
                      }, {
                        default: _withCtx((scope) => [
                          (
                    scope.row.Status !== STATUS_RENEWABLE.ACTIVE &&
                    !scope.row.isReSend
                  )
                            ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                                _createElementVNode("button", {
                                  type: "button",
                                  onClick: _withModifiers(($event: any) => (
                      handleSendInvitation(scope.$index, scope.row)
                    ), ["prevent"])
                                }, [
                                  _createVNode(BaseSvgIcon, {
                                    class: "inline-block text-cafe-dark hover:text-blue-400 w-5 h-5",
                                    name: "active_account"
                                  })
                                ], 8, _hoisted_27)
                              ]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["data"])), [
                    [_directive_loading, isLoadingSupplyChainsData.value]
                  ]),
                  _createElementVNode("div", _hoisted_28, [
                    _createVNode(_component_el_pagination, {
                      class: "justify-end",
                      layout: "prev, pager, next",
                      "pager-count": paginationSupplyChain.value.TotalPage,
                      "current-page": paginationSupplyChain.value.CurrentPage,
                      "page-size": paginationSupplyChain.value.Limit,
                      total: paginationSupplyChain.value.TotalRow,
                      onCurrentChange: handleCurrentChange
                    }, null, 8, ["pager-count", "current-page", "page-size", "total"])
                  ])
                ])
              ])
            ]))
          : (_openBlock(), _createBlock(MissPermission, { key: 1 }))
      ], 2))
}
}

})